// Album.js
import React, {useEffect, useState} from 'react';
import AlbumItem from '../components/item/AlbumItem';
import { ManageImage } from "../data/mutiImage";
import axios from '../data/custom/axios';
 //import "./album.css"
import "./album2.css"
//import "./album3.css"
//import "./album4.css"
import $ from 'jquery';
import 'magnific-popup';

const Show1 =() =>{
  const n = 14;
  const img1 = 'https://picsum.photos/id/152/1200/1200';
  const img2 = 'https://picsum.photos/id/360/1200/1200';

  useEffect(() => {
    const grid = document.querySelector('.grid');
    
    for (let j = 0; j < n; j++) {
      for (let i = 0; i < n; i++) {
        const cell = document.createElement('div');
        cell.classList.add('cell');
        cell.setAttribute('style', `--i: ${i}; --j: ${j};`);
        grid.appendChild(cell);
      }
    }

    return () => {
      grid.innerHTML = ''; // Clean up cells on component unmount
    };
  }, [n]);

  return (
    <div className="show1 container">
      <input className='show1input' type="checkbox" id="image" />
      <label className="grid" htmlFor="image" style={{ '--n': n, '--img1': `url(${img1})`, '--img2': `url(${img2})` }}>
        {/* Grid cells will be dynamically appended here */}
      </label>
    </div>
  );
}
const Show2 =() =>{
  useEffect(() => {
    $('.gallery-link').magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      closeBtnInside: false,
      mainClass: 'mfp-with-zoom mfp-img-mobile',
      image: {
        verticalFit: true,
        titleSrc: (item) => item.el.find('figcaption').text() || item.el.attr('title'),
      },
      zoom: {
        enabled: true,
      },
      gallery: {
        enabled: true,
        navigateByImgClick: false,
        tCounter: '',
      },
      disableOn: () => $(window).width() > 640,
    });
  }, []);

  const imageIds = [1081, 1014, 267, 266, 634, 923, 682, 173, 943];
  const getRandomSize = () => 200 * [5, 6, 7, 8].sort(() => 0.5 - Math.random())[0];

  return (
    <article className="gallery">
      {imageIds.map((i) => {
        const height = getRandomSize();
        const width = getRandomSize();
        const imgSrc = `https://unsplash.it/${width}/${height}?image=${i}`;
        return (
          <a key={i} className="gallery-link" href={imgSrc}>
            <figure className="gallery-image">
              <img src={imgSrc} width={width} height={height} alt="Gallery" />
              <figcaption>Photo caption</figcaption>
            </figure>
          </a>
        );
      })}
      <footer id="footer" role="contentinfo">
        <div className="container">
          <a className="logo" href="https://codepen.io/collection/XRoxGR" rel="home">
            Calibration theme
          </a>
          <a className="copy" href="https://mobilemarkup.com" target="_blank" rel="noopener noreferrer">
            &copy; mobileMarkup.com
          </a>
        </div>
      </footer>
    </article>
  );
}

class MaterialPhotoGallery {
  constructor(elem) {
    this.elem = elem;
    // Initialize your gallery logic here
  }
  // Add methods and logic for the gallery
}


const Show3 = () => {
  useEffect(() => {
    const elem = document.querySelector('.m-p-g');
    if (elem) {
      const gallery = new MaterialPhotoGallery(elem);
    }
  }, []);

  return (
    <div className="m-p-g">
      <div className="m-p-g__thumbs" data-google-image-layout data-max-height="350">
        <img src="http://unsplash.it/600/400?image=940" data-full="http://unsplash.it/1200/800?image=940" className="m-p-g__thumbs-img" alt="img1" />
        <img src="http://unsplash.it/640/450?image=906" data-full="http://unsplash.it/1280/900?image=906" className="m-p-g__thumbs-img" alt="img2" />
        <img src="http://unsplash.it/550/420?image=885" data-full="http://unsplash.it/1100/840?image=885" className="m-p-g__thumbs-img" alt="img3" />
        <img src="http://unsplash.it/650/450?image=823" data-full="http://unsplash.it/1300/900?image=823" className="m-p-g__thumbs-img" alt="img4" />
        <img src="http://unsplash.it/600/350?image=815" data-full="http://unsplash.it/1200/700?image=815" className="m-p-g__thumbs-img" alt="img5" />
        <img src="http://unsplash.it/560/500?image=677" data-full="http://unsplash.it/1120/1000?image=677" className="m-p-g__thumbs-img" alt="img6" />
        <img src="http://unsplash.it/670/410?image=401" data-full="http://unsplash.it/1340/820?image=401" className="m-p-g__thumbs-img" alt="img7" />
        <img src="http://unsplash.it/620/340?image=623" data-full="http://unsplash.it/1240/680?image=623" className="m-p-g__thumbs-img" alt="img8" />
        <img src="http://unsplash.it/790/390?image=339" data-full="http://unsplash.it/1580/780?image=339" className="m-p-g__thumbs-img" alt="img9" />
      </div>
      <div className="m-p-g__fullscreen"></div>
    </div>
  );
};


const Show4 =() =>{
  return (
    <div className="container">
    <div className="row">
      <div className="col-lg-12 text-center my-2">
        <h4>Isotope filter magical layouts with Bootstrap 4</h4>
      </div>
    </div>
    <div className="portfolio-menu mt-2 mb-4">
      <ul>
        <li className="btn btn-outline-dark active" data-filter="*">All</li>
        <li className="btn btn-outline-dark" data-filter=".gts">Girls T-shirt</li>
        <li className="btn btn-outline-dark" data-filter=".lap">Laptops</li>
        <li className="btn btn-outline-dark text" data-filter=".selfie">selfie</li>
      </ul>
    </div>
    <div className="portfolio-item row">
      <div className="item selfie col-lg-3 col-md-4 col-6 col-sm">
        <a href="https://image.freepik.com/free-photo/stylish-young-woman-with-bags-taking-selfie_23-2147962203.jpg" className="fancylight popup-btn" data-fancybox-group="light"> 
          <img className="img-fluid" src="https://image.freepik.com/free-photo/stylish-young-woman-with-bags-taking-selfie_23-2147962203.jpg" alt="" />
        </a>
      </div>
    </div>
  </div>
      
  )
}

const Album = () => {
  return (
    <div >
      <Show2/>
    </div>
  );
};

export default Album;
