import React, { useEffect, useState } from 'react';
import "./style/aboutitem.css"
import a1 from "../../image/doitackachhang.jpg"
import phattrienImg from "../../image/phattrien.png"
import { CRUDPosts } from "../../data/posts";
import { CRUDPostingCategories } from "../../data/postingCategories";
import axios from "../../data/custom/axios";

function AboutItem() {

  const { GetPosts } = CRUDPosts();
  const { GetPostingCategories } = CRUDPostingCategories();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
const [GetIntro, SetIntro] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoryData = await GetPostingCategories();
      const postData = await GetPosts();
      if (categoryData && postData) {
        const newsCategory = categoryData.find(ce => ce.categoryName.toLowerCase() === "trang chủ".toLowerCase());
        console.log("ádsknd:", newsCategory);
        if (newsCategory) {
          const filteredPosts = postData.filter(po => po.categoryId === newsCategory.categoryId);
          if(filteredPosts){
            setPosts(filteredPosts);
            console.log("s,dmf",posts)
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const showImage = (url) => {
    return (`${axios.defaults.baseURL}/img/${url}`)
  }

  const findPostByTitle = (title) => posts.find(post => post.title.toLowerCase() === title.toLowerCase());

    return(
      
  <div>
    <div className="container-fluid py-5">
      <div className="bg-2 bg-centre"></div>
      <div className="container">
        <div className="row g-5 ">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <h2 className="mb-4">
           SẢN PHẨM ĐÃ TRIỂN KHAI
            </h2>
            <p className="mb-4">
            {findPostByTitle("PHÁT TRIỂN BỀN VỮNG")?.introContent}
            </p>
            <div className="row g-3">
              <div className="col-sm-6">
                <h6 className="mb-3">
                  <i className="fa fa-check text-primary me-2" />
                  Tăng trưởng mạnh mẽ
                </h6>
                <h6 className="mb-0">
                  <i className="fa fa-check text-primary me-2" />
                Chất lượng dịch vụ cao
                </h6>
              </div>
              <div className="col-sm-6">
                <h6 className="mb-3">
                  <i className="fa fa-check text-primary me-2" />
                  Chuyên nghiệp
                </h6>
                <h6 className="mb-0">
                  <i className="fa fa-check text-primary me-2" />
                  Tiên Phong
                </h6>
              </div>
            </div>
            <div className="d-flex align-items-center mt-4">
              <a className="btn btn-orange rounded-pill px-4 me-3" href="/ve_kcl/su_menh">
                Tìm Hiểu Thêm
              </a>
             
            </div>
          </div>
          <div className="col-lg-6 " data-wow-delay="0.1s">
    
      <div className="row parallelogram ">
        <div className="back auto"></div>
        <img className="auto align-self-end" src={showImage(findPostByTitle("PHÁT TRIỂN BỀN VỮNG")?.imageUrl)} alt="Tăng Trưởng" /></div>
    </div>
        </div>
      </div>
      <div className="container">
        <div className="row g-5 ">
          <div className="col-lg-6  wow fadeIn" data-wow-delay="0.1s">
    
              <div className="row parallelogram ">
                <div className="back auto  jalign-self-start"></div>
                <img className="auto align-self-end" src={showImage(findPostByTitle("ĐỐI TÁC - KHÁCH HÀNG")?.imageUrl)} alt="Đối tác" /></div>
            </div>
    
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
    
            <h2 className="mb-4">
            ĐỐI TÁC - KHÁCH HÀNG
            </h2>
            <p className="mb-4">
            {findPostByTitle("ĐỐI TÁC - KHÁCH HÀNG")?.introContent}
            </p>
              <div className="row g-3">
              <div className="col-sm-6">
                <h6 className="mb-3">
                  <i className="fa fa-check text-primary me-2" />
                  Đối tác chiến lược
                </h6>
                <h6 className="mb-0">
                  <i className="fa fa-check text-primary me-2" />
                  Khách hàng của chúng tôi
                </h6>
              </div>
    
            </div>
            <div className="d-flex align-items-center mt-4">
              <a className="btn btn-orange rounded-pill px-4 me-3" href="/doi_tac">
                Tìm Hiểu Thêm
              </a>
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
    );
}
export default AboutItem;