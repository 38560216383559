import { BrowserRouter as Router,Routes, Route } from "react-router-dom";
import AppRouter from "./routes/AppRoutes"; 
import "./index.css";
import {React} from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ColorModeContext, useMode } from './admin/theme';
import {UserProvider} from "./admin/context/auth/UserContext"
import { AdminRouter } from './admin';
import { Pagenotfound } from "./components";


function App() {
  const [theme, colorMode] = useMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserProvider>
        <Router>
            <AppRouter />
            <AdminRouter />
            <Routes path="*" element={<Pagenotfound />} />
          </Router>
        </UserProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
