import React, { useState, useEffect, useRef } from "react";
import { Modal, Input, Form, message, Select } from 'antd';
import { CRUDPosts } from "../../data/posts";
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css'
import { IconButton } from "@mui/material";
import UploadImg from "../UploadImage/UploadImg";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Sun from "../../components/Sun";
const { Option } = Select;
const UpdatePostModal = (props) => {
  const [imagePreview, setImagePreview] = useState(false);
  const { open, onClose, Post, onUpdateSuccess, postcategory, isHistory } = props;
  const { UpdatePost } = CRUDPosts();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);


  // const uploadedImagesRef = useRef([]);
  // let Mutilimg;
  const [content, setContent] = useState('');

  const [isEditModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    console.log("post categori", postcategory);
    const now = new Date();
    if (Post) {
      console.log("post edit", Post);
      form.setFieldsValue({
        Id: Post.postId,
        title: Post.title,
        IntroContent: Post.introContent,
        content: Post.content,
        imageUrl: Post.imageUrl,
        postDate: Post.postDate,
        authorName: Post.authorName,
        categoryId: Post.categoryId,
        updatedDate: now.toISOString().slice(0, 16),
        year: Post.year,
        status: Post.status,
      });
    }
  }, [Post, form]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setConfirmLoading(true);
      const formData = new FormData();
      formData.append('id', Post.postId);
      formData.append('title', values.title);
      formData.append('IntroContent', values.IntroContent);
      formData.append('content', content);
      const imageFile = form.getFieldValue('image');
      if (imageFile) {
        if (imageFile.size > 10 * 1024 * 1024) {
          message.error("Dung lượng ảnh quá lớn, vui lòng chọn ảnh có dung lượng dưới 10MB");
          console.log("dung lượng ảnh quá lớn", imageFile.size);
          return;
        }
        formData.append('Image', imageFile);
      }
      formData.append('imageUrl', values.imageUrl); // Nếu bạn sử dụng Upload component để upload ảnh
      formData.append('postDate', values.postDate);
      formData.append('authorName', values.authorName);
      formData.append('categoryId', values.categoryId);
      formData.append('updatedDate', values.updatedDate);
      if (values.Year !== undefined && values.Year !== null) {
        formData.append('year', values.Year);
      }
      
      formData.append('status', values.status);
   //   console.log("nội dung trong formdata", [...formData]);
      await UpdatePost(formData);
      message.success('Post Update successfully');
      setImagePreview(null);
      form.resetFields(); // Reset fields after successful submission
      onClose();
      onUpdateSuccess();
    } catch (error) {
      console.error('Error Update post:', error);
      message.error('Failed to Update post. Please try again later.');
    } finally {
      setConfirmLoading(false);
    }
  };

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 2020; year <= currentYear; year++) {
    years.push(year);
  }

  const showImglist = () => {
    setIsModalOpen(true);
  };
  const closeImglist = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      width="55%"
      title="Edit Post"
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="Id"
          label="Post ID"
          rules={[{ required: true, message: 'Please input the post title!' }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="title"
          label="Tiêu đề"
          rules={[{ required: true, message: 'Please input the post title!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="IntroContent"
          label="Giới thiệu"
        >
          <Input.TextArea rows={9} />
        </Form.Item>
        <Form.Item
          name="content"
          label="Nội Dung Chính"
        >
          <IconButton
            onClick={showImglist}
          >
            <PermMediaIcon />
          </IconButton>
          <Sun
            content={form.getFieldValue('content')}
            onContentChange={setContent}
          />
        </Form.Item>
        <input
          type="file"
          accept="image/*"
          multiple="true"
          onChange={(e) => {
            const file = e.target.files[0];
            form.setFieldsValue({ image: file });

            // Tạo URL để preview ảnh
            if (file) {
              const previewURL = URL.createObjectURL(file);
              setImagePreview(previewURL);
            } else {
              setImagePreview(null);
            }
          }}
        />
        {imagePreview && (
          <div style={{ marginTop: '10px' }}>
            <img
              src={imagePreview}
              alt="Preview"
              style={{ maxWidth: '200px', maxHeight: '200px' }}
            />
          </div>
        )}
        <Form.Item
          name="imageUrl"
          label="Image URL"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="postDate"
          label="Ngày đăng"
        >
          <Input type="datetime-local" />
        </Form.Item>
        <Form.Item
          name="authorName"
          label="Tác Giả"
          rules={[{ required: true, message: 'Please input the author name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="categoryId"
          label="Loại Bài Viết"
          rules={[{ required: true, message: 'Please select the category ID!' }]}
        >
          <Select>
            {postcategory && postcategory.length > 0 ? (
              postcategory.map(category => (
                <Option key={category.categoryId} value={category.categoryId}>
                  {category.categoryName}
                </Option>
              ))
            ) : (
              <Option value="" disabled>
                No categories available
              </Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name="updatedDate"
          label="Ngày Cập Nhật"
        >
          <Input type="datetime-local" disabled />
        </Form.Item>
        {isHistory && (
          <Form.Item
            name="year"
            label="Year"
          >
            <Select placeholder="Select a year">
              {years.map(year => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="status"
          label="Trạng Thái"
          rules={[{ required: true, message: 'Please input the status!' }]}
        >
          <Select>
            <Option value={0}>active</Option>
            <Option value={1}>inactive  </Option>
          </Select>
        </Form.Item>
      </Form>
      <UploadImg
        open={isEditModalOpen}
        onClose={closeImglist}
      />
    </Modal>
  );
};

export default UpdatePostModal;
