import React, { useState, useEffect } from "react";
import { Modal, Input, Form, message } from 'antd';
import { CRUDBanners } from "../../data/banner";

import axios from '../../custom/axios';
const UpdateBannerModal = (props) => {
  const [imagePreview, setImagePreview] = useState(false);
  const { open, onClose, Banner, onUpdateSuccess } = props;
  const { UpdateBanner } = CRUDBanners();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  useEffect(() => {
    if (Banner) {
        const imageUrl =  `${axios.defaults.baseURL}/img/${Banner.imageData}` ;
        setImagePreview(imageUrl);
      form.setFieldsValue({
        Id: Banner.bannerId,
        bannerName: Banner.bannerName,
        image: imageUrl,
      });
   
    }
   
  }, [Banner, form]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setConfirmLoading(true);

      const formData = new FormData();
      formData.append('Id', Banner.bannerId);
      formData.append('bannerName', values.bannerName);
      formData.append('imageData', Banner.imageData);
      const imageFile = form.getFieldValue('image');
      if (imageFile) { 
if (imageFile.size > 10 * 1024 * 1024) {
          message.error("Dung lượng ảnh quá lớn, vui lòng chọn ảnh có dung lượng dưới 10MB");
          console.log("dung lượng ảnh quá lớn", imageFile.size);
          return;
        }
        formData.append('image', imageFile);
      }

      await UpdateBanner(formData);
      message.success('Banner updated successfully');
      onClose();
      onUpdateSuccess();
    } catch (error) {
      console.error('Error updating Banner:', error);
      message.error('Failed to update Banner. Please try again later.');
    } finally {
      setConfirmLoading(false);
    }
  };
  return (
   <Modal
       width="55%"
     title="Edit Banner"
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="bannerName"
          label="Banner Name"
          rules={[{ required: true, message: 'Please input the post content!' }]}
        >
          <Input className="uppercase-input" />
        </Form.Item>
          {/* <Form.Item name="image" label="Image">
            <Input/>
          </Form.Item> */}
        <Form.Item name="image" label="Image">
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              form.setFieldsValue({ image: file });
              const previewURL = URL.createObjectURL(file);
              setImagePreview(previewURL);
            }
          }}
        />
        {imagePreview && (
          <div style={{ marginTop: '10px' }}>
            <img
              src={imagePreview}
              alt="Preview"
              style={{ maxWidth: '200px', maxHeight: '200px' }}
            />
          </div>
        )}
      </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateBannerModal;
