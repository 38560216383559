import { Route, Routes } from "react-router-dom";
import Login from "../scenes/login/Login";
import PrivateRoute from "./PrivateRoutes";
import Dashboard from "../scenes/dashboard/Dashboard";
import User from "../scenes/user/Users";

import Layout from "../scenes/layout/Layout"; // Ensure you import the correct Layout component
import MutiImage from "../scenes/mutiImage/MutiImage";
import Banner from "../scenes/Banner/Banner";
import Post from "../scenes/posts/Post";
import ProductCategories from "../scenes/productCategory/ProductCategorys"
import PostingCategories from "../scenes/postingCategories/PostingCategories";
import  MutiProduct  from "../scenes/product/Muti_Products";
import Nav from "../scenes/nav/Navigation";
import Navlist from "../scenes/navList/NavList";
import Color from "../scenes/color/Color"
import Pdf from "../scenes/pdf/Pdf"

const AppRouter = () => { 
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* Protected Routes */}
        <Route path="/admin" element={<PrivateRoute />}>
          <Route element={<Layout />}>
            <Route index element={<Dashboard/>} /> 
            <Route path="/admin/user" element={<User />} />
            <Route path="/admin/banner" element={<Banner />} />
            <Route path="/admin/post/:id" element={<Post />} />
            <Route path="/admin/postingCategories" element={<PostingCategories />} />
            <Route path="/admin/productCategories" element={<ProductCategories />} />
            <Route path="/admin/products/:id" element={<MutiProduct/>}/>
            <Route path="/admin/mutiImage" element={<MutiImage />} />
            <Route path="/admin/nav" element={<Nav />} />
            <Route path="/admin/navlists" element={<Navlist />} />
            <Route path="/admin/color" element={<Color />} />
            <Route path="/admin/pdf" element={<Pdf />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default AppRouter;
