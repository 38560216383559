import React, { useState } from "react";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import axios from "../custom/axios";

const Sun = ({ content, onContentChange }) => {
  // Hàm xử lý upload ảnh
  const handleImageUploadBefore = async (files, info, uploadHandler) => {
    const file = files[0];
    const formData = new FormData();
    formData.append('files', file);

    try {
      const response = await axios.post(`/MutilImg/PostImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response && response.fileNames && response.fileNames.length > 0) {
        const imageUrl = `${axios.defaults.baseURL}/img/${response.fileNames[0]}`;
      //  console.log('Image URL:', imageUrl);

        // Sử dụng uploadHandler để chèn URL của ảnh vào nội dung của SunEditor
        uploadHandler({
          result: [{ url: imageUrl }] // Chèn URL của ảnh vào editor
        });
        
      //return false;
      } else {
        throw new Error('No file names returned from the server.');
      }
    } catch (error) {
      console.error('Image upload failed:', error);
      uploadHandler({
        result: [{ error: 'Failed to upload image' }] // Trả về lỗi nếu upload thất bại
      });
    }
    return undefined;
    // Trả về false để chặn việc chèn ảnh base64 vào SunEditor
    //uploadHandler();
  };

  // Hàm xử lý thay đổi nội dung trong editor
  const handleEditorChange = (newContent) => {
    onContentChange(newContent); // Truyền nội dung lên component cha
  };

  return (
    <>
      <SunEditor
        setContents={content} // Nhận nội dung từ props
        onImageUploadBefore={handleImageUploadBefore} // Chặn hành vi mặc định trước khi upload
        onChange={handleEditorChange} // Gọi khi nội dung thay đổi
        setOptions={{
          buttonList: [
            ['undo', 'redo'],
            ['bold', 'underline', 'italic', 'strike'],
            ['list', 'indent', 'outdent'],
            ['align', 'fontSize', 'formatBlock'],
            ['image', 'link'],
            ['removeFormat']
          ],
        }}
      />
    </>
  );
};

export default Sun;
