import React, { useEffect, useRef, useState } from "react";
import * as pdfjsLib from "pdfjs-dist";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const PdfPreview = ({ url }) => {
  
  const [imageSrc, setImageSrc] = useState(null);
  const canvasRef = useRef(null);
  const renderTaskRef = useRef(null);  // Thêm tham chiếu để theo dõi renderTask hiện tại
  useEffect(() => {
    const renderPdf = async () => {
      try {
        // Kiểm tra nếu URL không tồn tại hoặc không hợp lệ
        if (!url) {
          console.warn("URL không tồn tại");
          return;
        }

        // Tải tài liệu PDF
        const loadingTask = pdfjsLib.getDocument(url);
        const pdf = await loadingTask.promise;

        // Lấy trang đầu tiên của PDF
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1 });
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        // Cài đặt kích thước cho canvas
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        // Hủy render trước đó nếu có
        if (renderTaskRef.current) {
          renderTaskRef.current.cancel();
        }

        // Lưu render task để có thể hủy nếu cần
        renderTaskRef.current = page.render(renderContext);

        // Đợi render hoàn thành
        await renderTaskRef.current.promise;

        // Lấy hình ảnh từ canvas
        const imgData = canvas.toDataURL();
        setImageSrc(imgData);
      } catch (error) {
        // Bắt lỗi khi không thể tải hoặc render PDF
        console.error("Không thể tải hoặc render PDF:", error);
      }
    };

    if (url) {
      renderPdf();
    }

    // Cleanup khi component bị unmount hoặc khi URL thay đổi
    return () => {
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }
    };
  }, [url]);


  return (
    <>
      {imageSrc ? (
        <img src={imageSrc} alt="PDF preview" style={{ width: "100px" }} />
      ) : (
        <canvas ref={canvasRef} style={{ display: "none" }} />
      )}
    </>
  );
};

export default PdfPreview;
