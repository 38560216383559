import React, { useState, useEffect } from "react";
import { ManageImage } from "../../data/mutiImage";
import  DeleteImg from "../mutiImage/Delete"
import { Modal, Input, Form, message, Select, Drawer } from 'antd';
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { MDBIcon } from 'mdb-react-ui-kit'; 
import ImagePreview from "../../components/imagePreview";
import "./Drawer.css"

const UploadImg = (listImg) => {
  const { GetImagedist, getAllImage, CreateImage, DeleteMutiImage } = ManageImage();
  const [deleteimage, setdeleteImage] = useState([]);
  const {open,onClose } = listImg;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [img, setImg] = useState([]);

  const [layout, setLayout] = useState('12'); // Trạng thái để theo dõi kiểu sắp xếp hiện tại

  // Hàm để thay đổi kiểu sắp xếp
  const handleLayoutChange = (newLayout) => {
    setLayout(newLayout);
  };

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const response = await GetImagedist();
    if (response) {
      setImg(response.images);
    }
  };

  const handleModalCloseModalDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const showModalConfirmDeleteImg = (img, selectedFiles) => {
    setdeleteImage(img);
    setSelectedFiles(selectedFiles)
    setIsDeleteModalOpen(true);
  };

  const HandelUpload = async () => {
    const formData = new FormData();
    if(selectedFiles.length === 0) {
      message.error("Please select image");
      return;
    }
    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].size > 10*1024*1024 ){
        message.error("Dung lượng ảnh quá lớn, vui lòng chọn ảnh có dung lượng dưới 1MB");
        return;
      }
      formData.append(' ', selectedFiles[i]);
    }
    setIsLoading(true);
    try {
      const response = await CreateImage(formData);
      if (response) {
        message.success("Upload successfully");
        fetchData();
      }
      setSelectedFiles([]);
      setIsLoading(false);
    } catch (error) {
      message.error("Upload failed");
      setIsLoading(false);
    }
  };
  
  const handleCheckboxChange = (image) => {
    setSelectedImages((prevSelectedImages) => {
      if (prevSelectedImages.includes(image)) {
       
        return prevSelectedImages.filter((img) => img !== image);
      } else {
        return [...prevSelectedImages, image];
      }
    });
  }
  const Deletesuccessfully = async () => {
      fetchData();
  }
  return (
    <Drawer
    title="Upload image"
    placement="right"
    visible={open}
    onClose={onClose}
    width="20%"
    mask={false}
  >
    <div>
      <input className="inputfile" type="file" multiple onChange={handleFileChange} />
    </div>

    <div className="d-flex mb-2">
    <button className="uploadbtn" onClick={HandelUpload} disabled={isLoading}>
      <span className="uploadbtn_top">
        <MDBIcon fas icon="upload" />
        {isLoading ? 'Uploading...' : 'Upload'}
      </span>
    </button>
    
    {/* Nút để sắp xếp hình ảnh */}
     {/* Sử dụng d-flex và justify-content-end */}
    <div className="ms-auto arrang"> 
      {/* Các nút để thay đổi kiểu sắp xếp */}
      <button
          className={`px-1 btn-secondary ${layout === '12' ? 'active' : ''}`}
          onClick={() => handleLayoutChange('12')}
        >
          <MDBIcon className='arrange' icon='th-list' size='2x' />
        </button>
        <button
          className={`px-1 btn-secondary ${layout === '6' ? 'active' : ''}`}
          onClick={() => handleLayoutChange('6')}
        >
          <MDBIcon className='arrange' icon='th-large' size='2x' />
        </button>
        <button
          className={`px-1 btn-secondary ${layout === '4' ? 'active' : ''}`}
          onClick={() => handleLayoutChange('4')}
        >
          <MDBIcon className='arrange' icon='th' size='2x' />
        </button>
    </div>
    </div>
    <div>
      <div className="row">
        {img.map((image, index) => (
          <div className={`col-${layout}`} key={index}>  {/* Sắp xếp theo trạng thái */}
            <ImagePreview image={image} />
            <button className='deletebtn' onClick={() => showModalConfirmDeleteImg(image)}>
              <MDBIcon icon="trash-alt" /> 
            </button>
          </div>
        ))}
      </div>
    </div>

    <DeleteImg
      open={isDeleteModalOpen}
      onClose={handleModalCloseModalDelete}
      img={deleteimage}
      mutiImage={selectedImages}
      successfully={Deletesuccessfully}
    />
  </Drawer>
  );
};

export default UploadImg;