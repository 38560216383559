import axios from "../custom/axios";
import {message} from "antd";
const CRUDBanners = () => {
  const GetBanners = async () => {
    try {
      const response = await axios.get(`/banner`);
     // console.log(response);
      return response;
    
    } catch (error) {
      console.error("Error during fetching categories:", error);
      if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      return false;
    }
  };
  const CreateBanner =async (formData) => {
    try {
      const res = await axios.post(`/banner`,formData)
    //  console.log(res);
      return true; 
    } catch (error) {
      console.error('Error updating Banner:', error);
      if (error.response && error.response.name === 403 ) {
        message.error('Bạn không có quyền thêm danh mục này.');
      } else if (error.response && error.response.status === 400   || error.response.status === 500) {
        message.error('Vui lòng xóa sản phẩm trước.');
       } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      throw error; 
    }
  };

  const UpdateBanner =async (formData) => {
    try {
      const res = await axios.put(`/banner/${formData.get("Id")}`, formData);
     // console.log(res.data);
      return true; 
    } catch (error) {
      console.error('Error updating Banner:', error);
      if (error.response && error.response.name === 403 ) {
        message.error('Bạn không có quyền cập nhật danh mục này.');
      } else if (error.response && error.response.status === 400   || error.response.status === 500) {
        message.error('Vui lòng xóa sản phẩm trước.');
       } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      throw error; 
    }
  };
  const DeleteBannerById = async (id) =>{
    try {
      const res = await axios.delete(`/banner/${id}`);
     // console.log(res);
      return true;
    } catch (error) {
      console.error("Error delete Banner",error);
      if (error.response && error.response.name === 403 ) {
        message.error('Bạn không có quyền xóa danh mục này.');
      } else if (error.response && error.response.status === 400   || error.response.status === 500) {
        message.error('Vui lòng xóa sản phẩm trước.');
       } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      throw error;
    };
  }
  return { GetBanners, UpdateBanner, DeleteBannerById, CreateBanner };
}


export {CRUDBanners};
