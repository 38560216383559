
import React from "react";
import img from "../../image/bg-hero.png";
import axios from "../../data/custom/axios";
import noImage from "../../image/600x300.png";
import "./style/shop.css"
const ShopCard = ({ index, name, title, content, img, link, retailprice }) => {
    const backgroundImageStyle = {
        backgroundImage: "url(../../image/bg-hero.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    };

    let imageUrl = null;
    if (!img) {
        imageUrl = noImage;
    } else {
        imageUrl = `${axios.defaults.baseURL}/img/${img}`;
        console.log("img", imageUrl)
    }
    const formatPrice=(price)=>{
        if(price){
        return price.toLocaleString('vi-VN');
        }
        else{
            return price;
        } // Định dạng số theo tiêu chuẩn tiền tệ Việt Nam
    }
    return (
        <>
            <div className="col shopcard">
                <div className="card shadow-sm">
                    <div className="image-card">
                    <img src={imageUrl} className="card-img-center" alt="..." />
                    </div>
                    <div className="card-body">
                        <div className="clearfix mb-3">
                            <span className="float-start badge rounded-pill bg-primary">Sản Phẩm</span>
                            <span className="float-end price-hp">{formatPrice(retailprice)}<em style={{fontSize: "15px"}}>đ</em></span>
                        </div>
                        <h5 className="card-title">{name}</h5>

                        {/* Nút nằm ở góc dưới cùng bên phải */}
                        <div className="shopbtn">
                            <a href={link} className="btn btn-orange">Xem thêm</a>
                        </div>
                    </div>
                </div>
            </div>  
        </>
    );
};

export default ShopCard;