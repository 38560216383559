import axios from "../custom/axios";
import {message} from "antd";
const CRUDPdfs = () => {
  const GetPdfs = async () => {
    try {
      const response = await axios.get(`/Pdf`);
      // console.log(response);
      return response;
    
    } catch (error) {
      console.error("Error during fetching categories:", error);
      if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      return false;
    }
  };
  const CreatePdf=async (formData ) => {
   
    try {
      for (var pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
    }
      const res = await axios.Pdf(`/Pdf`,formData, {
        'Content-Type':'multipart/form-data'
      })
      console.log('nội dung trả về',res);
      return true; 
    } catch (error) {
      console.error('Error updating Pdf:', error);
      if (error.response && error.response.name === 403 ) {
        message.error('Bạn không có quyền thêm danh mục này.');
      } else if (error.response && error.response.status === 400   || error.response.status === 500) {
        message.error('Vui lòng xóa sản phẩm trước.');
       } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      throw error; 
    }
  };

  const UpdatePdf=async (formData) => {
    console.log("id của Pdf",formData.get('Id'));
    try {
      const res = await axios.put(`/Pdf/${formData.get('Id')}`, formData);
       console.log(res.data);
      
      return true; 
    } catch (error) {
      console.error('Error updating Pdf:', error);
      if (error.response && error.response.name === 403 ) {
        message.error('Bạn không có quyền cập nhật danh mục này.');
      } else if (error.response && error.response.status === 400   || error.response.status === 500) {
        message.error('Vui lòng xóa sản phẩm trước.');
       } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      throw error; 
    }
  };
  const DeletePdfById = async (id) =>{
    try {
      const res = await axios.delete(`/Pdfs/${id}`);
       console.log(res);
      return true;
    } catch (error) {
      console.error("Error delete Pdf",error);
      if (error.response && error.response.name === 403 ) {
        message.error('Bạn không có quyền xóa danh mục này.');
      } else if (error.response && error.response.status === 400   || error.response.status === 500) {
        message.error('Vui lòng xóa sản phẩm trước.');
       } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      throw error;
    };
  }
  return { GetPdfs, UpdatePdf, DeletePdfById, CreatePdf};
}
export {CRUDPdfs};
