import React from 'react'
// import ReactDOM from 'react-dom/client'
// import { Route, Routes } from "react-router-dom";
import App from '../App'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "./animate/animate.min.css"
import MainLayout from '../layout/MainLayout';
import ProductDetail from '../components/product/ProductDetail/ProductDetail';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  Navigation,
  Footer,
  Home,
  K_SOLUTION,
  K_TECHNICAL,
  K_HEALTHCARE,
  News,
  Partn,
  History,
  Corevalues,
  Mission,
  Profession,
  Network,
  Organizational,
  MainContent,
  Strategic,
  Fields,
  About,
  Accessory,
  Pagenotfound
} from "../components";
import Album from '../page/Album';
import ColorPicker from '../page/ColorPicker';
import ViewPdf from '../page/ViewPDF'
const AppRouter = () => {
  return (
    <Routes>
      <Route path ="/ViewPdf" element= {<ViewPdf/>}/>
      <Route path='/album' element = {<Album/>} />
    <Route path="/" element={<MainLayout />}>
    <Route path="/" element={<Home/>}/>
      <Route path="/ve_kcl" element={<About/>}/>
      <Route path="/ve_kcl/lich_su" element={<History />} />
      <Route path="/ve_kcl/su_menh" element={<Mission />} />
      <Route path="/ve_kcl/gia_tri_cot_loi" element={<Corevalues />} />
      <Route path="/ve_kcl/mang_luoi_hoat_dong" element={<Network />} />
      <Route path="/ve_kcl/So_do_to_chuc" element={<Organizational />} />
      <Route path="/ve_kcl/tam_nhin_chien_luoc" element={<Strategic />} />
      <Route path="/tin_tuc" element={<News />} />
      <Route path="/Viec-lam" element={<Profession />} />
      <Route path="/san_pham" element={<Fields />} />
      <Route path="/san_pham/k_solution" element={<K_SOLUTION />} />
      <Route path="/san_pham/k_healthtech" element={<K_HEALTHCARE />} />
      <Route path="/san_pham/k_technical" element={<K_TECHNICAL />} />
      <Route path='/san_pham/K_thiet-bi' element = {<Accessory/>} />
      <Route path="san_pham/bai_viet/:id" element={<ProductDetail/>} />
      <Route path="/tin_tuc/:id" element={<MainContent />} />
      <Route path="/doi_tac" element={<Partn />} />
      <Route path='/color-picker' element = {<ColorPicker/>} />
    </Route>
   
  </Routes>
  )
}

export default AppRouter;

