import React, { useState } from "react";
import { Modal, Input, Form, message } from 'antd';
import {CRUDBanners} from "../../data/banner";
const CreateBannerModal = (props) => {
  const { open, onClose, onUpdateSuccess } = props;
  const {CreateBanner} = CRUDBanners();
  const [imagePreview, setImagePreview] = useState(false);
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setConfirmLoading(true);
      const formData = new FormData();
      formData.append('bannerName', values.bannerName);
      const imageFile = form.getFieldValue('image');
      if (imageFile) { 
if (imageFile.size > 10 * 1024 * 1024) {
          message.error("Dung lượng ảnh quá lớn, vui lòng chọn ảnh có dung lượng dưới 10MB");
          console.log("dung lượng ảnh quá lớn", imageFile.size);
          return;
        }
        formData.append('image', imageFile);
      }

      await CreateBanner(formData);
      message.success('Post created successfully');
      form.resetFields(); // Reset fields after successful submission
      onClose();
      onUpdateSuccess();
    } catch (error) {
      console.error('Error creating post:', error);
      message.error('Failed to create post. Please try again later.');
    } finally {
      setConfirmLoading(false);
    }
  };


  return (
   <Modal
       width="55%"
     title="Create new category"
      visible={open}
      onOk={handleOk}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical">

        
      <Form.Item
          name="bannerName"
          label="Banner Name"
          rules={[{ required: true, message: 'Please input the post content!' }]}
        >
          <Input className="uppercase-input" />
        </Form.Item>
        {/* <Form.Item name="image" label="Image">
          <Input/>
        </Form.Item> */}
        <Form.Item name="image" label="Image">
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              form.setFieldsValue({ image: file });
              const previewURL = URL.createObjectURL(file);
              setImagePreview(previewURL);
            }
          }}
        />
        {imagePreview && (
          <div style={{ marginTop: '10px' }}>
            <img
              src={imagePreview}
              alt="Preview"
              style={{ maxWidth: '200px', maxHeight: '200px' }}
            />
          </div>
        )}
          
        
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateBannerModal;
