import React from 'react';
import { Link } from 'react-router-dom';
import './PageNotFound.css'; // Đảm bảo bạn có file CSS nếu tách style ra file riêng

const PageNotFound = () => {
  return (
    <div className='erro404'>
      <div className="cosmic-object" id="star1"></div>
      <div className="cosmic-object" id="star2"></div>
      <div className="cosmic-object" id="planet"></div>
      <div className="container">
        <h1>404</h1>
        <p>Đường dẫn không tồn tại</p>
        <Link to="/" className="btn">Trở về trang chủ</Link>
      </div>
    </div>
  );
};

export default PageNotFound;
